@import '../../styles/customMediaQueries.css';

.Section {
  width: 100vw;
  background: #3baa7f;

  @media (min-width: 200px) and (max-width: 767px) {
  }
}

.container {
  margin: 0 auto;

  padding: 22vh 10vw 10vh 10vw;

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 23vh 10vw 6vh 10vw;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 25vh 10vw 5vh 10vw;
    text-align: center;
  }
}

.description {
  color: white;
  line-height: normal;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: 'Gilroy-Bold';

  @media (min-width: 320px) and (max-width: 767px) {
    color: grey;
  }
}

.title {
  /* text-transform: uppercase; */
  color: black;
  font-size: 60px;
  line-height: normal;
  font-family: 'Gilroy-Bold';
}

.newsLetterSection {
  position: relative;
  height: 200px;
  width: 100%;
  display: block;
}
