@font-face {
    font-family: 'Gilroy-Regular';
    src: local('Gilroy-Regular'), url(./styles/fonts/Gilroy-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Gilroy-Bold';
    src: local('Gilroy-Bold'), url(./styles/fonts/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Gilroy-Heavy';
    src: local('Gilroy-Heavy'), url(./styles/fonts/Gilroy-Heavy.ttf) format('truetype');
}

@font-face {
    font-family: 'Londrina Solid';
    src: local('Londrina Solid'), url(./styles//fonts//LondrinaSolid-Regular.ttf) format('truetype');
}