.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.backgroundColorTopBar {
  position: fixed;
  top: 0;
  z-index: var(--zIndexTopbar);
  width: 100vw;
}

.main {
  display: grid;
}
